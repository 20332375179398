import React from 'react';
import { Helmet } from 'react-helmet-async';
import ComparisonTable from '../../Components/ComparisonTable/ComparisonTable';
import '../../Components/ComparisonTable/ComparisonTable.css';

const ComparePage = () => {
  return (
    <>
      <Helmet>
        <title>Compare Solutions | ByteSand AI</title>
        <meta 
          name="description" 
          content="Compare ByteSand's AI solutions with traditional approaches and see how our technology can transform your business operations."
        />
      </Helmet>

      <div className="page-container">
        <ComparisonTable />
      </div>
    </>
  );
};

export default ComparePage; 