import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { HelmetProvider, Helmet } from 'react-helmet-async';
import ScrollToTop from './Components/ScrollToTop';
import NeuralBackground from './Components/Background/NeuralBackground';
import './Components/Background/NeuralBackground.css';

// Import Pages
import Home from './Pages/Home';
import CaseStudiesPage from './Pages/CaseStudies';
import ComparePage from './Pages/Compare';
import AboutPage from './Pages/About';
import FAQPage from './Pages/FAQ';
import PrivacyPolicy from './Components/PrivacyPolicy/PrivacyPolicy';

// Import Components
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';

// The Layout component provides consistent background and structure across all pages
const Layout = ({ children }) => (
  <div className="app-container">
    <NeuralBackground />
    {children}
  </div>
);

const App = () => {
  return (
    <HelmetProvider>
      <Router>
        <ScrollToTop />
        <NeuralBackground />
        <Layout>
          <Helmet>
            {/* Default meta tags for the entire app */}
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="theme-color" content="#000000" />
            <link rel="canonical" href="https://bytesand.ai" />
            
            {/* Default title and description */}
            <title>ByteSand | AI Business Automation Solutions</title>
            <meta 
              name="description" 
              content="Transform your business operations with AI voice agents and automation solutions. Reduce costs, improve efficiency, and deliver better customer experiences."
            />

            {/* Open Graph meta tags for social sharing */}
            <meta property="og:type" content="website" />
            <meta property="og:title" content="ByteSand | AI Business Automation Solutions" />
            <meta property="og:description" content="Transform your business operations with AI voice agents and automation solutions." />
            <meta property="og:url" content="https://bytesand.ai" />
            
            {/* Twitter Card meta tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="ByteSand | AI Business Automation Solutions" />
            <meta name="twitter:description" content="Transform your business operations with AI voice agents and automation solutions." />
          </Helmet>

          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/case-studies" element={<CaseStudiesPage />} />
            <Route path="/compare" element={<ComparePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/faq" element={<FAQPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
          <Footer />
        </Layout>
      </Router>
    </HelmetProvider>
  );
};

export default App;