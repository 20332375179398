// src/Components/PrivacyPolicy/PrivacyPolicy.jsx
import React from 'react';
import { Helmet } from 'react-helmet-async';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
    return (
        <>
            <Helmet>
                <title>Privacy Policy | ByteSand AI Business Automation</title>
                <meta 
                    name="description" 
                    content="ByteSand's privacy policy outlines how we handle your data with our AI automation solutions. Learn about our data collection, storage, and protection practices."
                />
                <link rel="canonical" href="https://bytesand.ai/privacy-policy" />
                
                {/* Open Graph meta tags */}
                <meta property="og:title" content="Privacy Policy | ByteSand AI Business Automation" />
                <meta property="og:description" content="ByteSand's privacy policy outlines how we handle your data with our AI automation solutions." />
                <meta property="og:url" content="https://bytesand.ai/privacy-policy" />
                
                {/* Twitter Card meta tags */}
                <meta name="twitter:title" content="Privacy Policy | ByteSand AI Business Automation" />
                <meta name="twitter:description" content="ByteSand's privacy policy outlines how we handle your data with our AI automation solutions." />
            </Helmet>

            <div className="privacy-policy-container">
                <div className="privacy-policy-content">
                    <h1 className="privacy-policy-title">Privacy Policy</h1>
                    
                    <h2 className="section-title">Lead Generation Forms and Data Collection</h2>
                    <p>We collect information through Lead Generation Forms provided by LinkedIn. By submitting these forms, you agree to the following terms regarding the collection and use of your data:</p>
                    
                    <h3 className="subsection-title">Data Collection and Usage</h3>
                    <p>We collect only lawful and necessary information through our Lead Generation Forms. We explicitly do not collect or request:</p>
                    <ul>
                        <li>Racial or ethnic origin</li>
                        <li>Political opinions</li>
                        <li>Religious or philosophical beliefs</li>
                        <li>Trade union membership</li>
                        <li>Health or sexual orientation data</li>
                        <li>Criminal offense data</li>
                        <li>Government-issued IDs</li>
                        <li>Login credentials or passwords</li>
                        <li>Financial account information</li>
                        <li>Information about minors under 16</li>
                    </ul>

                    <h3 className="subsection-title">Data Storage and Retention</h3>
                    <p>Form data is initially stored with LinkedIn for 90 days, during which time we transfer and securely store it in our systems. We maintain appropriate security measures to protect your information.</p>

                    <h3 className="subsection-title">Data Sharing</h3>
                    <p>We may share your information with third-party service providers who assist us in operating our business. These service providers:</p>
                    <ul>
                        <li>Are bound by confidentiality obligations</li>
                        <li>Can only use your data on our behalf</li>
                        <li>Must comply with our privacy standards and applicable laws</li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default PrivacyPolicy;