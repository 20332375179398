import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import styles from './FAQSection.module.css';

const FAQSection = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
      question: "How long does it take to implement an AI voice agent?",
      answer: "Implementation typically takes 2-3 weeks. This includes initial setup, customization to your business processes, integration with your existing systems, testing, and training. We provide a dedicated implementation team to ensure a smooth transition with minimal disruption to your operations."
    },
    {
      question: "What types of tasks can be automated?",
      answer: "Our AI voice agents can automate a wide range of tasks including appointment scheduling, order processing, customer inquiries, follow-up calls, payment reminders, satisfaction surveys, and basic technical support. They excel at handling repetitive, structured conversations while seamlessly transferring complex issues to human agents."
    },
    {
      question: "How do you ensure quality control with AI automation?",
      answer: "We maintain quality through multiple layers: AI models trained on industry-specific data, real-time conversation monitoring, regular performance audits, and detailed analytics. All conversations are recorded and analyzed for quality assurance, and our system includes built-in escalation protocols for complex situations."
    },
    {
      question: "What's the typical ROI timeframe?",
      answer: "Most businesses see positive ROI within 2-3 months of implementation. With average cost savings of 60-80% on call handling and a 40% increase in customer response rates, the initial investment is typically recovered quickly. Our ROI calculator can provide a customized estimate based on your specific business metrics."
    },
    {
      question: "Do you sign non-disclosure agreements?",
      answer: "Yes, we sign NDAs before beginning any project discussions. We take your intellectual property and confidentiality seriously and are happy to use your standard NDA or provide our template."
    },
    {
      question: "What does the onboarding process look like?",
      answer: "Our onboarding process begins with a free operations audit to understand your needs. We then create a detailed implementation plan, establish success metrics, and set up your AI voice agent. You'll have a dedicated success manager throughout the process to ensure smooth deployment."
    }
  ];

  // FAQ Schema markup
  const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": faqs.map(faq => ({
      "@type": "Question",
      "name": faq.question,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": faq.answer
      }
    }))
  };

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className={styles.faqSection} id="faq">
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(faqSchema)}
        </script>
      </Helmet>

      <h2>FAQs</h2>
      <p className={styles.faqSubtitle}>Common questions about our AI automation solutions</p>
      
      <div className={styles.faqContainer}>
        {faqs.map((faq, index) => (
          <div 
            key={index}
            className={`${styles.faqItem} ${openIndex === index ? styles.active : ''}`}
            onClick={() => toggleFAQ(index)}
          >
            <div className={styles.faqQuestion}>
              <span>{faq.question}</span>
              {openIndex === index ? <RemoveIcon /> : <AddIcon />}
            </div>
            <div className={`${styles.faqAnswer} ${openIndex === index ? styles.open : ''}`}>
              <p>{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FAQSection;