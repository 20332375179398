import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import './Navbar.css';
import LogoBlank from '../Images/brandmark-design-logo-transparent.png';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScheduleCall = () => {
    window.open('https://calendly.com/bytesandai/30min', '_blank');
  };

  const menuItems = [
    { title: 'Home', to: '/' },
    { title: 'Case Studies', to: '/case-studies' },
    { title: 'Compare', to: '/compare' },
    { title: 'About', to: '/about' },
    { title: 'FAQ', to: '/faq' }
  ];

  const renderNavLink = (item, isMobile = false) => {
    const isActive = location.pathname === item.to;
    const className = isMobile ? "mobile-nav-link" : "nav-link";
    const activeClassName = isMobile ? "mobile-nav-link-active" : "nav-link-active";

    return (
      <Link
        key={item.to}
        to={item.to}
        className={`${className} ${isActive ? activeClassName : ''}`}
        onClick={() => {
          if (isMobile) setIsOpen(false);
        }}
      >
        {item.title}
      </Link>
    );
  };

  return (
    <nav className={`navbar ${scrolled ? 'navbar-scrolled' : ''}`}>
      <div className="navbar-container">
        {/* Logo */}
        <Link
          to="/"
          className="navbar-logo"
        >
          <img src={LogoBlank} alt="Logo" className="logo-image" />
          <span className="logo-text">ByteSand</span>
        </Link>

        {/* Desktop Menu */}
        <div className="navbar-links">
          {menuItems.map(item => renderNavLink(item))}
          <button className="navbar-cta desktop-cta" onClick={handleScheduleCall}>
            Schedule Call
          </button>
        </div>

        {/* Mobile Menu Button */}
        <button
          className="mobile-menu-button"
          onClick={() => setIsOpen(!isOpen)}
          aria-label="Toggle mobile menu"
        >
          {isOpen ? <CloseIcon /> : <MenuIcon />}
        </button>

        {/* Mobile Menu */}
        <div className={`mobile-menu ${isOpen ? 'mobile-menu-open' : ''}`}>
          {menuItems.map(item => renderNavLink(item, true))}
          <button className="mobile-nav-link" onClick={handleScheduleCall}>
            Schedule Call
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;