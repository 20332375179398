import React from 'react';
import './ComparisonTable.css';
import { Check, X } from 'lucide-react';

const ComparisonTable = () => {
  const comparisons = [
    {
      category: "Customer Service",
      before: [
        "Long wait times (5+ minutes)",
        "Limited to business hours",
        "Inconsistent responses",
        "High staff turnover",
        "Manual call logging"
      ],
      after: [
        "Instant response (< 10 seconds)",
        "24/7 availability",
        "Consistent, on-brand responses",
        "Reliable, always-on service",
        "Automatic call logging & analytics"
      ]
    },
    {
      category: "Operational Efficiency",
      before: [
        "Staff tied up with routine calls",
        "High training costs",
        "Limited call capacity",
        "Manual data entry",
        "No multilingual support"
      ],
      after: [
        "Staff focused on complex tasks",
        "One-time AI setup",
        "Unlimited concurrent calls",
        "Automated data capture",
        "Multiple language support"
      ]
    },
    {
      category: "Business Impact",
      before: [
        "Missed revenue opportunities",
        "High operational costs",
        "Limited scalability",
        "No performance analytics",
        "Customer dissatisfaction"
      ],
      after: [
        "24/7 revenue generation",
        "80% cost reduction",
        "Instant scalability",
        "Real-time analytics",
        "Improved satisfaction scores"
      ]
    }
  ];

  return (
    <div className="comparison-section">
      <div className="comparison-header">
        <h2>Transform Your Business Operations</h2>
        <p>See how AI Voice Agents revolutionize your business processes</p>
      </div>

      <div className="comparison-tables">
        {comparisons.map((comparison, index) => (
          <div key={index} className="comparison-table">
            <h3>{comparison.category}</h3>
            <div className="table-content">
              <div className="before-column">
                <h4>Before</h4>
                <ul>
                  {comparison.before.map((item, i) => (
                    <li key={i}>
                      <X className="icon-x" />
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="after-column">
                <h4>After AI Implementation</h4>
                <ul>
                  {comparison.after.map((item, i) => (
                    <li key={i}>
                      <Check className="icon-check" />
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ComparisonTable; 