import React, { useState, useEffect } from 'react';
import './ROICalculator.css';
import { Calculator, DollarSign, Clock, Users } from 'lucide-react';

const ROICalculator = () => {
  const [inputs, setInputs] = useState({
    callsPerDay: '50',
    avgCallDuration: '5',
    employeeCost: '25',
    workingDays: '22',
    employeeCount: '3'
  });

  const [results, setResults] = useState({
    monthlyHoursSaved: 0,
    monthlyCostSavings: 0,
    yearlyROI: 0
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Only allow numbers and empty string
    if (value === '' || /^\d*$/.test(value)) {
      setInputs(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  useEffect(() => {
    // Convert empty strings to 0 for calculations
    const numericInputs = {
      callsPerDay: Number(inputs.callsPerDay) || 0,
      avgCallDuration: Number(inputs.avgCallDuration) || 0,
      employeeCost: Number(inputs.employeeCost) || 0,
      workingDays: Number(inputs.workingDays) || 0,
      employeeCount: Number(inputs.employeeCount) || 0
    };

    // Calculate ROI based on inputs
    const hoursPerDay = (numericInputs.callsPerDay * numericInputs.avgCallDuration) / 60;
    const monthlyHours = hoursPerDay * numericInputs.workingDays * numericInputs.employeeCount;
    const monthlyCost = monthlyHours * numericInputs.employeeCost;
    
    // Assuming 85% of calls can be automated
    const automatedHours = monthlyHours * 0.85;
    const costSavings = automatedHours * numericInputs.employeeCost;
    
    // ROI calculation (assuming $100/month service cost)
    const yearlyROI = ((costSavings * 12) - (1200)) / 1200 * 100;

    setResults({
      monthlyHoursSaved: automatedHours.toFixed(1),
      monthlyCostSavings: costSavings.toFixed(0),
      yearlyROI: yearlyROI.toFixed(0)
    });
  }, [inputs]);

  return (
    <div className="roi-calculator">
      <div className="roi-header">
        <Calculator className="roi-icon" />
        <h2>ROI Calculator</h2>
        <p>Calculate your potential savings with AI Voice Agents</p>
      </div>

      <div className="calculator-grid">
        <div className="input-section">
          <h3>Your Business Metrics</h3>
          <div className="input-group">
            <label>
              Number of employees handling calls
              <input
                type="text"
                name="employeeCount"
                value={inputs.employeeCount}
                onChange={handleInputChange}
                placeholder="0"
              />
            </label>
          </div>
          <div className="input-group">
            <label>
              Average calls per day (per employee)
              <input
                type="text"
                name="callsPerDay"
                value={inputs.callsPerDay}
                onChange={handleInputChange}
                placeholder="0"
              />
            </label>
          </div>
          <div className="input-group">
            <label>
              Average call duration (minutes)
              <input
                type="text"
                name="avgCallDuration"
                value={inputs.avgCallDuration}
                onChange={handleInputChange}
                placeholder="0"
              />
            </label>
          </div>
          <div className="input-group">
            <label>
              Employee hourly cost ($)
              <input
                type="text"
                name="employeeCost"
                value={inputs.employeeCost}
                onChange={handleInputChange}
                placeholder="0"
              />
            </label>
          </div>
          <div className="input-group">
            <label>
              Working days per month
              <input
                type="text"
                name="workingDays"
                value={inputs.workingDays}
                onChange={handleInputChange}
                placeholder="0"
              />
            </label>
          </div>
        </div>

        <div className="results-section">
          <h3>Your Potential Savings</h3>
          <div className="results-grid">
            <div className="result-card">
              <Clock className="result-icon" />
              <div className="result-content">
                <h4>Hours Saved Monthly</h4>
                <p>{results.monthlyHoursSaved} hours</p>
              </div>
            </div>
            <div className="result-card">
              <DollarSign className="result-icon" />
              <div className="result-content">
                <h4>Monthly Cost Savings</h4>
                <p>${results.monthlyCostSavings}</p>
              </div>
            </div>
            <div className="result-card">
              <Users className="result-icon" />
              <div className="result-content">
                <h4>Annual ROI</h4>
                <p>{results.yearlyROI}%</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ROICalculator; 