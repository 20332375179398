import React from 'react';
import { Helmet } from 'react-helmet-async';
import CaseStudies from '../../Components/CaseStudies/CaseStudies';
import '../../Components/CaseStudies/CaseStudies.css';

const CaseStudiesPage = () => {
  return (
    <>
      <Helmet>
        <title>Case Studies | ByteSand AI Solutions</title>
        <meta 
          name="description" 
          content="Discover how businesses have transformed their operations with ByteSand's AI solutions through real-world case studies and success stories."
        />
      </Helmet>

      <div className="page-container">
        <CaseStudies />
      </div>
    </>
  );
};

export default CaseStudiesPage; 