import React from 'react';
import { ArrowRight, TrendingUp, Clock, Phone, Calendar, DollarSign } from 'lucide-react';
import './CaseStudies.css';

const CaseStudyCard = ({ industry, title, description, metrics }) => (
  <div className="case-study-card">
    <div className="case-study-industry">{industry}</div>
    <h3>{title}</h3>
    <p className="case-study-description">{description}</p>
    
    <div className="case-study-metrics">
      {metrics.map((metric, index) => (
        <div key={index} className="metric-item">
          {metric.icon}
          <span className="metric-text">{metric.text}</span>
        </div>
      ))}
    </div>
    
    {/* <a href="#" className="read-more-btn">
      Read Full Case Study <ArrowRight size={16} />
    </a> */}
  </div>
);

const CaseStudies = () => {
  const caseStudies = [
    {
      industry: "Urgent Care",
      title: "How TGH Urgent Care uses conversational AI to manage a surge in patient calls",
      description: "TGH Urgent Care was dealing with a significant surge in patient calls. The service reps were overwhelmed with an average of 543 calls per day, resulting in an erratic call answer rate, sometimes only getting 20% of the inbound calls. Since implementing AI voice bots, they're reduced the number of calls coming to the phone by 40%",
      metrics: [
        {
          icon: <Calendar size={20} className="metric-icon" />,
          text: "80% of call answered during peak hours"
        },
        {
          icon: <Phone size={20} className="metric-icon" />,
          text: "40% reduction in inbound calls"
        },
        {
          icon: <Clock size={20} className="metric-icon" />,
          text: "90 hours per week saved in staff time"
        }
      ]
    },
    {
      industry: "Restaurant",
      title: "How a UK pub chain handles 70% of its calls using AI voice bots",
      description: "The pub uses a voice assistant that could take, edit, and cancel reservations, as well as answer questions about the menu and the pub's location. The voice assistant is able to handle 70% of the calls, and the restaurant takes reservatioon calls 24 hours a day, 7 days a week. The voice assistant is able to handle 70% of the calls, and the restaurant takes reservatioon calls 24 hours a day, 7 days a week.",
      metrics: [
        {
          icon: <Phone size={20} className="metric-icon" />,
          text: "70% of phone orders processed automatically"
        },
        {
          icon: <Clock size={20} className="metric-icon" />,
          text: "100% of calls answered"
        },
        {
          icon: <DollarSign size={20} className="metric-icon" />,
          text: "3% increase in revenue"
        }
      ]
    },
    {
      industry: "Medical Practice",
      title: "Automating medical center reception services with voice AI",
      description: "By automating routine interactions, voice AI solutions provide 24/7 patient access, improve patient retention, and cater to diverse linguistic and technological proficiency levels, making healthcare services more accessible and efficient",
      metrics: [
        {
          icon: <Calendar size={20} className="metric-icon" />,
          text: "800% increase in number of vaccination appointments booked"
        },
        {
          icon: <Phone size={20} className="metric-icon" />,
          text: "86% reduction in calls answered by reception staff"
        },
        {
          icon: <TrendingUp size={20} className="metric-icon" />,
          text: "24% increase in overall calls handled"
        }
      ]
    }
  ];

  return (
    <section className="case-studies-section" id="case-studies">
      <div className="case-studies-container">
        <div className="case-studies-header">
          <h2>Case Studies</h2>
          <p>Real results from real businesses using our AI solutions</p>
        </div>
        
        <div className="case-studies-grid">
          {caseStudies.map((study, index) => (
            <CaseStudyCard key={index} {...study} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default CaseStudies; 