import React from 'react';
import { Helmet } from 'react-helmet-async';
import About from '../../Components/About/About';
import '../../Components/About/About.css';
import '../../Components/About/AboutBox.css';

const AboutPage = () => {
  return (
    <>
      <Helmet>
        <title>About Us | ByteSand AI Solutions</title>
        <meta 
          name="description" 
          content="Learn about ByteSand's mission to transform businesses through AI innovation and meet the team behind our cutting-edge solutions."
        />
      </Helmet>

      <div className="page-container">
        <About />
      </div>
    </>
  );
};

export default AboutPage; 